import { useEffect, useState } from 'react';
import { Typography } from '@ds';
import clsx from 'clsx';
import useSWR from 'swr';
import { useRefinitivTokenQuery } from '@/apollo/generated';
import PriceChangeV2 from '@/components/layouts/price-change-v2';
import { useCurrentCompanyProfileUser } from '@/contexts/current-company-profile-user-context';
import fetcher, { fetchOptions } from '@/utils/fetcher';
import { RefinitivQuoteData, getFieldValue } from '@/utils/refinitiv';

// interface TSXQuoteData {
//   percentChange: number;
//   price: number;
// }

// V2 exists purely to handle UI change for athena uplift
// There are a few places that use the ticker price around the site, so better to seperate out for now

const TickerSkeleton = () => {
  return (
    <div className="flex w-[100px] animate-pulse flex-col items-end gap-2">
      <span className="h-4 w-3/4 rounded-md bg-gray-200" />
      <div className="grid h-4 w-full grid-cols-2 gap-1">
        <span className="h-full rounded-md bg-gray-200" />
        <span className="h-full rounded-md bg-gray-200" />
      </div>
    </div>
  );
};

const TickerPrice = () => {
  const {
    currentCompanyProfileUser: {
      profile: {
        isUnlisted,
        ticker: { listingKey, marketKey, refinitivIdentificationCode },
      },
    },
  } = useCurrentCompanyProfileUser();

  const { data: refinitivTokenData, refetch } = useRefinitivTokenQuery();
  const [price, setPrice] = useState<number>();
  const [priceChange, setPriceChange] = useState<number>();
  const [currency, setCurrency] = useState<string>();
  const [tickerLoading, setTickerLoading] = useState(true);
  // const [tsxPrice, setTsxPrice] = useState<number>();
  // const [tsxPriceChange, setTsxPriceChange] = useState<number>();

  const token = refinitivTokenData?.token?.value;

  const params = {
    listingKey,
    marketKey,
    refinitivIdentificationCode: refinitivIdentificationCode || '',
    token: token || '',
  };

  const queryString = new URLSearchParams(params);

  const { data, error } = useSWR<RefinitivQuoteData, Error>(
    token && !isUnlisted
      ? `/api/refinitiv/quote-lists/get-simple-data?${queryString}`
      : null,
    fetcher,
    fetchOptions
  );

  // const tsxMapping: { [key: string]: string } = {
  //   'asx:mth': 'MSG',
  //   'lse:cusn': 'CUSN',
  // };

  // const tsxListingKey = tsxMapping[`${marketKey}:${listingKey}`];

  // Temp fixes for dual listing with TSX data
  // const { data: tsxData } = useSWR<TSXQuoteData, Error>(
  //   tsxListingKey ? `/api/tsx/get-quote?listingKey=${tsxListingKey}` : null,
  //   fetcher,
  //   fetchOptions
  // );

  // useEffect(() => {
  //   if (typeof tsxData?.price === 'number') {
  //     setTsxPrice(tsxData.price);
  //   }

  //   if (typeof tsxData?.percentChange === 'number') {
  //     setTsxPriceChange(tsxData.percentChange);
  //   }
  // }, [tsxData]);

  useEffect(() => {
    let ignore = false;

    if (data && !ignore) {
      const cfLast = getFieldValue(data, 'CF_LAST');
      const pctchng = getFieldValue(data, 'PCTCHNG');
      const cfCurrency = getFieldValue(data, 'CF_CURRENCY');

      if (typeof cfLast === 'number') {
        setPrice(cfLast);
      }

      if (typeof pctchng === 'number') {
        setPriceChange(pctchng);
      } else {
        setPriceChange(0);
      }

      if (typeof cfCurrency === 'string') {
        setCurrency(cfCurrency);
      }
    }

    setTickerLoading(false);

    return () => {
      ignore = true;
    };
  }, [data]);

  useEffect(() => {
    if (error && error.message.includes('Token expired')) {
      // Token expired, get a new token
      refetch();
    }
  }, [error, refetch]);

  const transformPriceString = (price: number, currency?: string) => {
    switch (currency) {
      case 'AUD':
        return price.toLocaleString('en-AU', {
          currency: 'AUD',
          maximumFractionDigits: 3,
          minimumFractionDigits: 2,
          style: 'currency',
        });

      case 'CAD':
        return price.toLocaleString('en-CA', {
          currency: 'CAD',
          maximumFractionDigits: 3,
          minimumFractionDigits: 2,
          style: 'currency',
        });

      case 'GBX':
        return `GBX ${price.toLocaleString('en-GB', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })}`;

      case 'USD':
        return price.toLocaleString('en-US', {
          currency: 'USD',
          maximumFractionDigits: 3,
          minimumFractionDigits: 2,
          style: 'currency',
        });
    }
  };
  if (price !== undefined && priceChange !== undefined) {
    const priceString = transformPriceString(price, currency);
    return tickerLoading ? (
      <TickerSkeleton />
    ) : (
      <>
        <div className="flex flex-row items-end justify-end gap-2">
          {/* {tsxPrice && (
            <div className="flex items-center gap-2">
              <div>
                <Typography className="text-gray-800" variant="text-label-sm">
                  {`TSXV:${tsxListingKey}`}
                </Typography>
                <div className="flex flex-row items-center gap-1">
                  <Typography
                    className={clsx(
                      `min-w-fit text-gray-800`,
                      priceChange > 0 && 'text-green-600',
                      priceChange < 0 && 'text-red-600'
                    )}
                    variant="text-body-sm"
                  >
                    {transformPriceString(tsxPrice, 'CAD')}
                  </Typography>
                  <PriceChangeV2 priceChange={tsxPriceChange || 0} />
                </div>
              </div>
              <div className="h-8 w-px bg-gray-200" />
            </div>
          )} */}
          <div>
            <Typography className="text-gray-800" variant="text-label-sm">
              {marketKey.toUpperCase()}:{listingKey.toUpperCase()}
            </Typography>
            <div className="flex flex-row items-center gap-1">
              <Typography
                className={clsx(
                  `min-w-fit text-gray-800`,
                  priceChange > 0 && 'text-green-600',
                  priceChange < 0 && 'text-red-600'
                )}
                variant="text-body-sm"
              >
                {priceString}
              </Typography>
              <PriceChangeV2 priceChange={priceChange} />
            </div>
          </div>
        </div>
      </>
    );
  }

  return <TickerSkeleton />;
};

export default TickerPrice;
