import { Typography } from '@ds';
import clsx from 'clsx';

interface Props {
  priceChange: number;
}

const PriceChangeV2: React.ComponentType<Props> = ({ priceChange }) => {
  return (
    <Typography className={clsx('text-gray-500')} variant="text-body-sm">
      {priceChange.toLocaleString('en-AU', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })}
      %
    </Typography>
  );
};

export default PriceChangeV2;
