import * as cookie from 'cookie';

const COOKIE_KEY = 'athena_changelog';
const COOKIE_AGE = 60 * 60 * 24 * 365; // 1 year

interface CookieObject {
  readChangelogIds: string[];
}

export function setChangelogCookie(input: CookieObject): void {
  const cookieValue = JSON.stringify(input);

  const options = {
    maxAge: COOKIE_AGE,
    path: '/',
  };

  if (typeof document === 'undefined') {
    return;
  }

  document.cookie = cookie.serialize(COOKIE_KEY, cookieValue, options);
}

export function getChangelogCookie(): CookieObject | undefined {
  if (typeof document === 'undefined') {
    return;
  }

  const cookieHeader = document.cookie;
  const cookies = cookie.parse(cookieHeader);
  const cookieValue = cookies[COOKIE_KEY];

  if (cookieValue) {
    try {
      return JSON.parse(cookieValue);
    } catch (error) {
      return {
        readChangelogIds: [],
      };
    }
  }

  return undefined;
}
