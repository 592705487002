// CustomiseCookies.tsx
import React from 'react';
import { Button, Typography } from '@ds';
import { Checkbox } from '@ds';
import { CheckIcon, XIcon } from '@heroicons/react/outline';
import { Controller, Control } from 'react-hook-form';
import { CookieConsent } from '@leaf/helpers';

interface CustomiseCookiesProps {
  control: Control<CookieConsent>;
  onCancel: () => void;
  onSubmit: () => void;
}

const CustomiseCookies: React.FC<CustomiseCookiesProps> = ({
  control,
  onCancel,
  onSubmit,
}) => {
  return (
    <form
      className="relative mx-auto max-w-prose overflow-y-auto"
      onSubmit={onSubmit}
    >
      <button className="absolute right-0 top-0" onClick={() => onCancel()}>
        <XIcon className="h-6 w-6 text-white" />
      </button>
      <Typography
        className="mb-5 text-center sm:text-left"
        variant="text-heading-md"
      >
        Customise cookies
      </Typography>
      <div className="mb-5 flex flex-col gap-5">
        <label className="flex gap-5">
          <div className="mt-1 h-4 w-4">
            <Checkbox disabled checked="yes" />
          </div>

          <div className="flex-1 text-center sm:text-left">
            <Typography variant="text-label-md">
              Strictly necessary cookies
            </Typography>
            <Typography className="text-gray-200" variant="text-body-sm">
              These are cookies that are necessary for our website to function
              and cannot be switched off in our systems. For example, they are
              used to store your privacy preferences or enable you to log into
              secure areas of our website.
            </Typography>
          </div>
        </label>

        <Controller
          control={control}
          name="analytics"
          render={({ field: { onChange, value } }) => (
            <div className="flex gap-5">
              <div className="mt-1 h-4 w-4 text-primary-green-dark">
                <Checkbox
                  checked={value ? 'yes' : 'no'}
                  onClick={() => onChange(!value)}
                />
              </div>

              <div
                className="flex-1 cursor-pointer text-center sm:text-left"
                onClick={() => onChange(!value)}
              >
                <Typography variant="text-label-md">
                  Performance cookies
                </Typography>
                <Typography className="text-gray-200" variant="text-body-sm">
                  These cookies allow us to count visits and traffic sources, so
                  we can measure and improve the performance of our site and
                  understand which parts of our website are the most and least
                  popular. They do not identify you as an individual.
                </Typography>
              </div>
            </div>
          )}
        />
      </div>
      <div className="flex flex-1 items-center justify-center gap-5 md:justify-end">
        <div
          className="cursor-pointer whitespace-nowrap hover:underline"
          onClick={onCancel}
        >
          Cancel
        </div>
        <Button
          LeadingIcon={() => <CheckIcon className="h-6 w-6" />}
          type="submit"
        >
          Accept
        </Button>
      </div>
    </form>
  );
};

export default CustomiseCookies;
