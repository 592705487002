import querystring, { ParsedUrlQuery } from 'querystring';

// New routes file for navigation refresh
// We could use this as a framework for removing old files/folders and tidy up codebase

// This new routes file should have all the "desired" routes, but the folders are not setup
// Please add to this file as we migrate old routes to the new structure

// The plan is to rollout the file & route changes in sections to make it easier to test what breaks

const routes = {
  analyse: {
    boardReport: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/analyse/board-report`,
    },
    engagement: {
      announcements: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/analyse/engagement/announcements`,
      },
      href: (marketListingKey: string) =>
        `/${marketListingKey}/analyse/engagement`,
      investorHub: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/analyse/engagement/investor-hub`,
      },
      leadConversion: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/analyse/engagement/lead-conversion`,
      },
      updates: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/analyse/engagement/updates`,
      },
    },
    investors: {
      brokers: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/analyse/investors/brokers`,
      },
      hnw: {
        href: (marketListingKey: string, query?: string) =>
          query
            ? `/${marketListingKey}/analyse/investors/hnw?${query}`
            : `/${marketListingKey}/analyse/investors/hnw`,
      },
      holdings: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/analyse/investors/holdings`,
      },
      href: (marketListingKey: string) =>
        `/${marketListingKey}/analyse/investors`,
      profitability: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/analyse/investors/profitability`,
      },
      reachability: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/analyse/investors/reachability`,
      },
      tradingActivity: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/analyse/investors/trading-activity`,
      },
    },
    keyMetrics: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/analyse/key-metrics`,
    },
    registry: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/analyse/registry`,
    },
  },
  audience: {
    allInvestors: {
      contacts: {
        contact: {
          href: (marketListingKey: string, id: string) =>
            `/${marketListingKey}/audience/all-investors/contacts/${id}`,
        },
      },
      href: (marketListingKey: string, query?: string) =>
        query
          ? `/${marketListingKey}/audience/all-investors?${query}`
          : `/${marketListingKey}/audience/all-investors`,
      importContactList: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/audience/all-investors/import-contact-list`,
      },
      register: {
        shareholder: {
          href: (marketListingKey: string, id: string) =>
            `/${marketListingKey}/audience/all-investors/register/${id}`,
        },
      },
    },
    registerOverview: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/audience/register-overview`,
    },
    segments: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/audience/segments`,
      list: {
        createList: {
          href: (marketListingKey: string, query?: ParsedUrlQuery) =>
            query
              ? `/${marketListingKey}/engage/audiences/segments/create-list?${querystring.stringify(
                  query
                )}`
              : `/${marketListingKey}/engage/audiences/segments/create-list`,
        },
        href: (marketListingKey: string, id: string, query?: ParsedUrlQuery) =>
          query
            ? `/${marketListingKey}/engage/audiences/segments/${id}?${querystring.stringify(
                query
              )}`
            : `/${marketListingKey}/engage/audiences/segments/${id}`,
      },
    },
    shareholderInsights: {
      brokers: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/audience/shareholder-insights/brokers`,
      },
      hnw: {
        href: (marketListingKey: string, query?: string) =>
          query
            ? `/${marketListingKey}/audience/shareholder-insights/hnw?${query}`
            : `/${marketListingKey}/audience/shareholder-insights/hnw`,
      },
      holdings: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/audience/shareholder-insights/holdings`,
      },
      href: (marketListingKey: string) =>
        `/${marketListingKey}/audience/shareholder-insights`,
      profitability: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/audience/shareholder-insights/profitability`,
      },
      reachability: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/audience/shareholder-insights/reachability`,
      },
      tradingActivity: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/audience/shareholder-insights/trading-activity`,
      },
    },
  },
  auth: {
    forgotPassword: {
      href: '/auth/reset-password',
    },
    login: {
      href: '/auth/login',
    },
    resetPassword: {
      href: (token: string) => `/auth/reset-password/${token}`,
    },
  },
  confirmDetails: {
    href: (marketListingKey: string) => `/${marketListingKey}/confirm-details`,
  },
  engage: {
    campaigns: {
      announcements: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/engage/campaigns?tab=announcements`,
      },
      campaign: {
        emailContentEdit: {
          href: (marketListingKey: string, campaignId: string) =>
            `/${marketListingKey}/engage/campaigns/${campaignId}/email-content-edit`,
        },
        href: (marketListingKey: string, campaignId: string) =>
          `/${marketListingKey}/engage/campaigns/${campaignId}`,
      },
      content: {
        announcement: {
          href: (
            marketListingKey: string,
            announcementId: string,
            detailPage?: 'true'
          ) =>
            detailPage
              ? `/${marketListingKey}/engage/campaigns/content/announcements/${announcementId}?detailPage=${detailPage}`
              : `/${marketListingKey}/engage/campaigns/content/announcements/${announcementId}`,
        },
        update: {
          href: (
            marketListingKey: string,
            updateId: string,
            detailPage?: 'true'
          ) =>
            detailPage
              ? `/${marketListingKey}/engage/campaigns/content/updates/${updateId}?detailPage=${detailPage}`
              : `/${marketListingKey}/engage/campaigns/content/updates/${updateId}`,
        },
      },
      href: (marketListingKey: string, tab?: 'announcements' | 'updates') =>
        tab
          ? `/${marketListingKey}/engage/campaigns?tab=${tab}`
          : `/${marketListingKey}/engage/campaigns`,
      settings: {
        automatedDistribution: {
          announcements: {
            configuration: {
              href: (marketListingKey: string) =>
                `/${marketListingKey}/engage/campaigns/settings/automated-distribution/announcements/configuration`,
            },
            viewSettings: {
              href: (marketListingKey: string) =>
                `/${marketListingKey}/engage/campaigns/settings/automated-distribution/announcements/view-settings`,
            },
          },
          href: (marketListingKey: string) =>
            `/${marketListingKey}/engage/campaigns/settings/automated-distribution`,
          updates: {
            configuration: {
              href: (marketListingKey: string) =>
                `/${marketListingKey}/engage/campaigns/settings/automated-distribution/updates/configuration`,
            },
            viewSettings: {
              href: (marketListingKey: string) =>
                `/${marketListingKey}/engage/campaigns/settings/automated-distribution/updates/view-settings`,
            },
          },
          welcomeEmail: {
            configuration: {
              href: (marketListingKey: string) =>
                `/${marketListingKey}/engage/campaigns/settings/automated-distribution/welcome-email/configuration`,
            },
          },
        },
        email: {
          href: (marketListingKey: string) =>
            `/${marketListingKey}/engage/campaigns/settings/email`,
        },
        href: (marketListingKey: string) =>
          `/${marketListingKey}/engage/campaigns/settings`,
        templates: {
          href: (marketListingKey: string) =>
            `/${marketListingKey}/engage/campaigns/settings/templates`,
        },
      },
      socials: {
        href: (
          marketListingKey: string,
          socialPlatform: 'linkedin' | 'twitter',
          mediaType: 'announcements' | 'updates',
          id: string
        ) =>
          `/${marketListingKey}/engage/campaigns/socials/${socialPlatform}/${mediaType}/${id}`,
      },
      updates: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/engage/campaigns?tab=updates`,
      },
    },
    content: {
      announcement: {
        analytics: {
          href: (marketListingKey: string, announcementId: string) =>
            `/${marketListingKey}/engage/content/announcements/${announcementId}?tab=analytics`,
        },
        edit: {
          href: (marketListingKey: string, announcementId: string) =>
            `/${marketListingKey}/engage/content/announcements/${announcementId}/edit`,
        },

        href: (
          marketListingKey: string,
          announcementId: string,
          tab?: 'analytics' | 'distribution' | 'questions' | 'survey-responses'
        ) =>
          tab
            ? `/${marketListingKey}/engage/content/announcements/${announcementId}?tab=${tab}`
            : `/${marketListingKey}/engage/content/announcements/${announcementId}`,
        pdf: {
          href: (marketListingKey: string, announcementId: string) =>
            `/${marketListingKey}/engage/content/announcements/${announcementId}/pdf`,
        },
      },
      announcements: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/engage/content?tab=announcements`,
      },
      href: (
        marketListingKey: string,
        tab?: 'announcements' | 'updates' | 'ai-summaries'
      ) =>
        tab
          ? `/${marketListingKey}/engage/content?tab=${tab}`
          : `/${marketListingKey}/engage/content`,
      manageQuestions: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/engage/content/manage-questions`,
      },
      prepareAnnouncement: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/engage/content?open=prep-announcement`,
      },
      preparedAnnouncement: {
        href: (marketListingKey: string, preparedAnnouncementId: string) =>
          `/${marketListingKey}/engage/content/prepared-announcement/${preparedAnnouncementId}`,
      },
      settings: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/engage/content/settings`,
      },
      update: {
        edit: {
          href: (marketListingKey: string, updateId: string) =>
            `/${marketListingKey}/engage/content/updates/${updateId}/edit`,
        },
        href: (marketListingKey: string, updateId: string, editPage?: 'true') =>
          editPage
            ? `/${marketListingKey}/engage/content/updates/${updateId}?editPage=${editPage}`
            : `/${marketListingKey}/engage/content/updates/${updateId}`,
      },
      updates: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/engage/content?tab=updates`,
      },
    },
    hubBuilder: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/engage/hub-builder`,
      page: {
        block: {
          href: (marketListingKey: string, slug: string, blockId: string) =>
            `/${marketListingKey}/engage/hub-builder/${slug}?blockId=${blockId}`,
        },
        editHeader: {
          href: (marketListingKey: string, slug: string) =>
            `/${marketListingKey}/engage/hub-builder/${slug}?isEditingHeader=true`,
        },
        editSettings: {
          href: (marketListingKey: string, slug: string) =>
            `/${marketListingKey}/engage/hub-builder/${slug}?isEditingSettings=true`,
        },
        editTheme: {
          href: (marketListingKey: string, slug: string) =>
            `/${marketListingKey}/engage/hub-builder/${slug}?isEditingTheme=true`,
        },
        href: (marketListingKey: string, slug: string) =>
          `/${marketListingKey}/engage/hub-builder/${slug}`,
      },
    },
    investorHub: {
      generalSettings: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/engage/investor-hub/general-settings`,
      },
      href: (marketListingKey: string) =>
        `/${marketListingKey}/engage/investor-hub`,
      managePages: {
        governancePageEditor: {
          edit: {
            href: (marketListingKey: string) =>
              `/${marketListingKey}/engage/investor-hub/manage-pages/governance-page-editor/edit`,
          },
        },
        href: (marketListingKey: string) =>
          `/${marketListingKey}/engage/investor-hub/manage-pages`,
        teamPageEditor: {
          edit: {
            href: (marketListingKey: string) =>
              `/${marketListingKey}/engage/investor-hub/manage-pages/team-page-editor/edit`,
          },
        },
        welcomePageEditor: {
          edit: {
            href: (marketListingKey: string) =>
              `/${marketListingKey}/engage/investor-hub/manage-pages/welcome-page-editor/edit`,
          },
        },
      },
      registeredInvestors: {
        certificate: {
          href: (marketListingKey: string, certificateId: string) =>
            `/${marketListingKey}/engage/investor-hub/registered-investors/certificates/${certificateId}`,
        },
        href: (marketListingKey: string) =>
          `/${marketListingKey}/engage/investor-hub/registered-investors`,
      },
    },
    links: {
      create: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/engage/links/create`,
      },
      href: (marketListingKey: string) => `/${marketListingKey}/engage/links`,
      link: {
        href: (marketListingKey: string, id: string) =>
          `/${marketListingKey}/engage/links/${id}`,
      },
    },
  },
  home: {
    href: (marketListingKey: string) => `/${marketListingKey}`,
  },
  index: {
    href: '/',
  },
  myAccount: {
    href: (marketListingKey: string) => `/${marketListingKey}/my-account`,
    notificationPreferences: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/my-account/notification-preferences`,
    },
    privacyAndSecurity: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/my-account/privacy-and-security`,
    },
    profile: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/my-account/profile`,
    },
  },
  raise: {
    history: {
      href: (marketListingKey: string) => `/${marketListingKey}/raise/history`,
    },
    placementReport: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/raise/placement-report`,
    },
    predictions: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/raise/predictions`,
    },
    raiseBuilder: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/raise/raise-builder`,
    },
  },
  settings: {
    email: {
      href: (marketListingKey: string) => `/${marketListingKey}/settings/email`,
    },
    general: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/settings/general`,
    },
    href: (marketListingKey: string) => `/${marketListingKey}/settings`,
    investorHubDomain: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/settings/investor-hub-domain`,
    },
    investorHubTheme: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/settings/investor-hub-theme`,
    },
    registry: {
      href: (marketListingKey: string) =>
        `/${marketListingKey}/settings/registry`,
    },
    socialMedia: {
      chooseLinkedinOrg: {
        href: (marketListingKey: string) =>
          `/${marketListingKey}/settings/social-media/choose-linkedin-org`,
      },
      href: (marketListingKey: string) =>
        `/${marketListingKey}/settings/social-media`,
    },
    team: {
      href: (marketListingKey: string) => `/${marketListingKey}/settings/team`,
    },
  },
};

export default routes;
