import { Dispatch, Fragment, SetStateAction, useState } from 'react';
import analytics from '@analytics';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { Badge, Button, Typography } from '@ds';
import { Dialog, Transition } from '@headlessui/react';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { Asset, EntryCollection, EntryFieldTypes } from 'contentful';
import dayjs from 'dayjs';
import DOMPurify from 'isomorphic-dompurify';
import capitalize from 'lodash/capitalize';
import Image from 'next/image';
import Link from 'next/link';
import { TRACKING_EVENTS } from '@/utils/tracking-events';

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener noreferrer');
  }
});

export type AthenaChangelogEntry = {
  contentTypeId: 'athenaChangelog';
  fields: {
    description: EntryFieldTypes.RichText;
    productTier: EntryFieldTypes.Text<'all' | 'base' | 'premium'>;
    region: EntryFieldTypes.Text<'all' | 'au' | 'uk'>;
    targetUrl: EntryFieldTypes.Text;
    thumbnails: EntryFieldTypes.AssetLink;
    title: EntryFieldTypes.Text;
    type: EntryFieldTypes.Text<'bug_fix' | 'new_feature' | 'uplift'>;
  };
};

export type EntriesResponseObject = EntryCollection<
  AthenaChangelogEntry,
  undefined,
  string
>;

interface ChangelogModalProps {
  data?: EntriesResponseObject;
  marketListingKey: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const ChangelogModal: React.ComponentType<ChangelogModalProps> = ({
  data,
  marketListingKey,
  open,
  setOpen,
}) => {
  const maxPage = data ? data.items.length : 0;

  const [page, setPage] = useState(1);

  function closeModal() {
    setOpen(false);
  }

  const renderContent = () => {
    if (data) {
      const item = data.items[page - 1];

      const badgeColor = () => {
        switch (item.fields.type) {
          case 'bug_fix':
            return 'sky';
          case 'new_feature':
            return 'sunflower';
          default:
            return 'violet';
        }
      };

      const thumbnails = item.fields.thumbnails as Asset;
      const imageUrl = `https:${thumbnails.fields.file?.url}`;

      const cleanedTargetUrl = item.fields.targetUrl.replace(/^\//, ''); // remove the "/" from the start of the string
      const actionUrl = `/${marketListingKey}/${cleanedTargetUrl}`;

      return (
        <>
          <div>
            <div className="flex items-center justify-between space-x-2">
              <Badge color={badgeColor()} size="xs">
                {capitalize(item.fields.type).replace('_', ' ')}
              </Badge>

              <Typography className="text-gray-500" variant="text-caption">
                {dayjs(item.sys.createdAt).format('D MMMM YYYY')}
              </Typography>
            </div>

            <Typography variant="text-heading-sm">
              {item.fields.title}
            </Typography>
          </div>

          <Image
            alt="changelog-thumbnail"
            className="h-44 w-full rounded-md object-cover"
            height={176}
            src={imageUrl}
            width={352}
          />

          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                documentToHtmlString(item.fields.description, {
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, next) => {
                      const content = next(node.content);

                      return content === ''
                        ? '<p><br /></p>'
                        : `<p>${content}</p>`;
                    },
                  },
                })
              ),
            }}
            className="min-h-36 whitespace-pre-wrap break-words text-gray-500"
          />

          <div>
            <Link
              href={actionUrl}
              onClick={() => {
                analytics.track(TRACKING_EVENTS.changelog.targetUrlClicked, {
                  contentfulEntryId: item.sys.id,
                  contentfulEntryTitle: item.fields.title,
                });
                closeModal();
              }}
            >
              <Typography className="text-amplify-green-700 underline">
                See {item.fields.type.replace('_', ' ')}
              </Typography>
            </Link>
          </div>
        </>
      );
    }

    return null;
  };

  return (
    <Transition appear as={Fragment} show={open}>
      <Dialog
        as="div"
        className="z-modal relative hidden md:block"
        onClose={closeModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed bottom-[56px] left-[256px] overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[400px] space-y-4 overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex items-center justify-between">
                  <Typography variant="text-heading-md">
                    What&apos;s new ✨
                  </Typography>

                  <XIcon
                    className="h-5 w-5 cursor-pointer"
                    onClick={closeModal}
                  />
                </div>

                {renderContent()}

                <div className="flex items-center justify-between">
                  <Button
                    LeadingIcon={ArrowLeftIcon}
                    disabled={page === 1}
                    variant="secondary-gray"
                    onClick={() => {
                      analytics.track(TRACKING_EVENTS.changelog.pageChanged, {
                        action: 'prev',
                      });
                      setPage((prev) => prev - 1);
                    }}
                  />

                  <Typography variant="text-body-sm">
                    {maxPage > 0 ? `${page} of ${maxPage}` : '0 of 0'}
                  </Typography>

                  <Button
                    LeadingIcon={ArrowRightIcon}
                    disabled={page === maxPage}
                    variant="secondary-gray"
                    onClick={() => {
                      analytics.track(TRACKING_EVENTS.changelog.pageChanged, {
                        action: 'next',
                      });
                      setPage((prev) => prev + 1);
                    }}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ChangelogModal;
