import { useCallback, useMemo, useState } from 'react';
import analytics from '@analytics';
import { Typography } from '@ds';
import {
  CheckIcon,
  ChevronRightIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react-v2/24/outline';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import { useCurrentCompanyProfileUser } from '@/contexts/current-company-profile-user-context';

const CompanyPicker: React.ComponentType = () => {
  const { currentCompanyProfileUser } = useCurrentCompanyProfileUser();

  const marketListingKey =
    currentCompanyProfileUser.profile.ticker.marketListingKey;

  const { asPath } = useRouter();

  const nextUrl = useCallback(
    (marketListingKey: string) => {
      return `/${marketListingKey}/${asPath.split('/').slice(2).join('/')}`;
    },
    [asPath]
  );
  const alphabeticalCompanies = [
    ...currentCompanyProfileUser.user.companyProfileUsers,
  ]
    // remove current company from list
    .filter((c) => c.profile.ticker.marketListingKey !== marketListingKey)
    .sort((a, b) => {
      if (a.profile.name.toLowerCase() < b.profile.name.toLowerCase()) {
        return -1;
      }
      if (a.profile.name.toLowerCase() > b.profile.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });

  const changeCompany = (nextMarketListingKey: string) => {
    if (
      marketListingKey != nextMarketListingKey &&
      currentCompanyProfileUser.simulatingAdminUserId
    ) {
      analytics.track('Changed Company', {
        admin_id: currentCompanyProfileUser.simulatingAdminUserId,
        from_market_listing_key: marketListingKey,
        to_market_listing_key: nextMarketListingKey,
      });
    }
    window.location.href = nextUrl(nextMarketListingKey);
  };

  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  const filteredCompanies = useMemo(() => {
    if (!searchTerm || !searchTerm.length) return alphabeticalCompanies;
    return alphabeticalCompanies.filter((c) => {
      const search = new RegExp(searchTerm, 'i');
      return (
        c.profile.name.match(search) ||
        c.profile.ticker.marketListingKey.match(search)
      );
    });
  }, [alphabeticalCompanies, searchTerm]);

  return (
    <div className="flex w-[220px] flex-col gap-4 divide-y divide-gray-200 rounded-lg border border-gray-200 bg-white pt-4 shadow-xl">
      <div className="flex w-full items-center justify-between gap-4 px-4 text-left">
        <div className="flex flex-row items-center gap-2.5">
          <div className="relative size-7 overflow-hidden rounded-full ">
            <Image
              alt=""
              layout="fill"
              objectFit="cover"
              src={currentCompanyProfileUser.profile.logoUrl}
            />
          </div>
          <Typography
            className="line-clamp-2 text-gray-800"
            variant="text-body-sm"
          >
            {currentCompanyProfileUser.profile.name}
          </Typography>
        </div>
        <CheckIcon className="size-4 text-gray-600" />
      </div>
      <div className="flex max-h-[240px] flex-col overflow-y-scroll py-2">
        {alphabeticalCompanies.length > 2 ? (
          <div className="px-1.5 pb-2">
            <label className="sr-only" htmlFor="search-companies">
              Search companies
            </label>
            <div className="flex w-full items-center gap-1.5 rounded-md border border-gray-200 bg-white px-2 py-1.5 transition-colors placeholder:text-gray-500 hover:border-gray-400">
              <MagnifyingGlassIcon className="size-3.5 text-gray-400" />
              <input
                className="w-full border-none p-0 text-sm outline-none"
                id="search-companies"
                name="search-companies"
                placeholder="Search by name or ticker..."
                type="input"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
        ) : (
          <div className="px-4 pb-2">
            <Typography className="text-gray-400" variant="text-caption-bold">
              Switch organisation
            </Typography>
          </div>
        )}
        <>
          {filteredCompanies.map((cpu) => (
            <a
              key={cpu.id}
              className="flex w-full cursor-pointer items-center justify-between gap-2 border-b border-gray-200 px-4 py-3 transition-colors last:border-b-0 hover:bg-gray-100"
              onClick={() => changeCompany(cpu.profile.ticker.marketListingKey)}
            >
              <div className="flex flex-row items-center gap-2.5">
                {/* <div className="relative size-7 overflow-hidden rounded-full bg-white">
                  <Image
                    alt=""
                    layout="fill"
                    objectFit="cover"
                    src={cpu.profile.logoUrl}
                  />
                </div> */}
                <Typography
                  className="line-clamp-2 min-w-0 flex-1 text-gray-800"
                  variant="text-body-sm"
                >
                  {cpu.profile.name}
                </Typography>
              </div>
              <ChevronRightIcon className="size-4 text-gray-600" />
            </a>
          ))}
        </>
      </div>
    </div>
  );
};

export default CompanyPicker;
