import { Fragment } from 'react';
import { Badge, Typography } from '@ds';
import { Disclosure, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react-v2/24/solid';
import clsx from 'clsx';
import Link from 'next/link';
import { HeroiconV2Type } from '@leaf/helpers';
import Tooltip from '@/components/ui/tooltip';
interface Props {
  Icon: HeroiconV2Type;
  defaultOpen?: boolean;
  items: {
    badge?: number;
    current?: boolean;
    href: string;
    isBeta?: boolean;
    isComingSoon?: boolean;
    isNew?: boolean;
    title: string;
  }[];
  title: string;
}

const DashboardMenuDisclosure: React.ComponentType<Props> = ({
  Icon,
  defaultOpen,
  items,
  title,
}) => {
  const isCurrent = items.some((item) => item.current);
  return (
    <Disclosure
      as="div"
      className="space-y-1"
      defaultOpen={defaultOpen ?? isCurrent}
    >
      {({ open }) => (
        <>
          <Disclosure.Button className="flex w-full items-center gap-2.5 rounded py-1.5 pl-2 pr-3 text-left text-gray-600 transition-colors hover:bg-gray-50">
            <Icon aria-hidden="true" className="size-4" />
            <Typography
              className="min-w-0 flex-1 truncate"
              variant="text-button-md"
            >
              {title}
            </Typography>
            <ChevronDownIcon
              className={clsx(
                'size-3.5 fill-gray-400 transition-transform',
                open && 'rotate-180'
              )}
            />
          </Disclosure.Button>
          <Transition
            as={Fragment}
            enter="transition duration-100 ease-out"
            enterFrom="transform opacity-0"
            enterTo="transform opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform opacity-100"
            leaveTo="transform opacity-0"
          >
            <Disclosure.Panel className="space-y-1 pl-6">
              {items.map((item) => (
                <Link
                  key={item.title}
                  className={clsx(
                    item.current
                      ? 'bg-amplify-green-50 text-amplify-green-700'
                      : 'text-gray-600 hover:bg-gray-50',
                    'group flex w-full gap-2.5 rounded px-3 py-1.5 text-left transition-colors'
                  )}
                  href={item.href}
                >
                  <Typography
                    className="flex min-w-0 flex-1 items-center gap-1 truncate"
                    component="div"
                    variant="text-button-md"
                  >
                    {item.title}{' '}
                    {item.isComingSoon && (
                      <Badge color="violet" size="xs">
                        Coming soon
                      </Badge>
                    )}
                    {item.isBeta && (
                      <Tooltip
                        className="!p-0"
                        content={
                          <div className="content w-56 shadow-lg">
                            <Typography
                              className="whitespace-normal break-words text-white"
                              variant="text-caption"
                            >
                              This feature is still under development. As you
                              enjoy early access, your feedback will help shape
                              the final product.
                            </Typography>
                          </div>
                        }
                        place="top"
                      >
                        <Badge color="violet" size="xs">
                          Beta
                        </Badge>
                      </Tooltip>
                    )}
                    {item.isNew && (
                      <Badge color="green" size="xs">
                        New
                      </Badge>
                    )}
                  </Typography>
                  <div
                    className={clsx(
                      item.badge ? 'flex' : 'hidden',
                      item.current
                        ? 'bg-secondary-grey-light-2 text-gray-900'
                        : 'bg-gray-400 text-white group-hover:bg-secondary-grey-light-2 group-hover:text-gray-900',
                      'h-5 w-8 items-center justify-center rounded-full'
                    )}
                  >
                    <Badge>{item.badge}</Badge>
                  </div>
                </Link>
              ))}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};

export default DashboardMenuDisclosure;
