import analytics from '@analytics';
import {
  Square2StackIcon,
  RectangleGroupIcon,
  CurrencyDollarIcon,
  UserGroupIcon,
  ChartBarIcon,
} from '@heroicons/react-v2/24/solid';
import { useRouter } from 'next/router';
import { usePastPlacementsQuery } from '@/apollo/generated';
import DashboardMenuDisclosure from '@/components/layouts/dashboard-menu-disclosure';
import DashboardMenuItem from '@/components/layouts/dashboard-menu-item';
import { useCurrentCompanyProfileUser } from '@/contexts/current-company-profile-user-context';
import { FLAGS } from '@/hooks/use-feature-toggles';
import { Permissions, usePermission } from '@/hooks/use-permission';
// import routesV2 from '@/utils/routes-v2';
import routes from '@/utils/routes';

type MenuItem = {
  badge?: number;
  current?: boolean;
  href: string;
  isBeta?: boolean;
  isComingSoon?: boolean;
  isNew?: boolean;
  onClick?: () => void;
  permission?: string;
  title: string;
};

const useMenuItemsWithPermissions = (items: MenuItem[]) => {
  const { currentCompanyProfileUser } = useCurrentCompanyProfileUser();

  return items.filter(
    (item) =>
      !item.permission ||
      currentCompanyProfileUser.permissions.some(
        (permission) => permission.name === item.permission
      )
  );
};

const DashboardMenu: React.ComponentType = () => {
  const {
    currentCompanyProfileUser,
    hasCompanyShareholderOfferPermission,
    isComputershare,
    isPremium,
    isUK,
    translate,
  } = useCurrentCompanyProfileUser();
  const { marketListingKey } = currentCompanyProfileUser.profile.ticker;

  const { asPath } = useRouter();

  const keyMetricsEnabled =
    currentCompanyProfileUser?.profile?.featuresEnabled.includes(
      FLAGS.keyMetrics
    );

  const sppEstimationEnabled =
    currentCompanyProfileUser?.profile?.featuresEnabled.includes(
      FLAGS.sppEstimation
    ) &&
    isPremium &&
    !isUK;

  const isWebsitePageBuilderMode =
    currentCompanyProfileUser?.profile?.featuresEnabled.includes(
      FLAGS.websitePageBuilder
    );

  const isWebinarsEnabled =
    currentCompanyProfileUser?.profile?.featuresEnabled.includes(
      FLAGS.webinars
    );

  // TAGS TODO: Remove this flag check when static lists are fully implemented
  const isStaticListsFlagEnabled =
    currentCompanyProfileUser?.profile?.featuresEnabled.includes(
      FLAGS.staticLists
    );

  const isBeneficialOwnersReportsMenuEnabled =
    currentCompanyProfileUser?.profile?.featuresEnabled.includes(
      FLAGS.beneficialOwnersReportsMenu
    );

  // Permissions
  const hasWebsitePermission = usePermission(Permissions.websitesAdmin);

  const { data: pastPlacementsData } = usePastPlacementsQuery();

  const engagementMenuItems = () => [
    ...(isWebsitePageBuilderMode
      ? []
      : [
          {
            current: asPath.startsWith(
              routes.engagement.investorHub.href(marketListingKey)
            ),
            href: routes.engagement.investorHub.managePages.href(
              marketListingKey
            ),
            title: 'Investor hub',
          },
        ]),
    ...(isWebsitePageBuilderMode
      ? [
          {
            current: asPath.startsWith(
              routes.engagement.hubBuilder.href(marketListingKey)
            ),
            href: routes.engagement.hubBuilder.href(marketListingKey),
            permission: Permissions.websitesAdmin,
            title: 'Hub builder',
          },
        ]
      : []),
    {
      current:
        asPath.startsWith(
          routes.engagement.interactiveMedia.href(marketListingKey)
        ) &&
        asPath !==
          routes.engagement.interactiveMedia.manageQuestions.href(
            marketListingKey
          ),
      href: routes.engagement.interactiveMedia.href(marketListingKey),
      title: 'Content',
    },
    {
      current:
        asPath ===
        routes.engagement.interactiveMedia.manageQuestions.href(
          marketListingKey
        ),
      href: routes.engagement.interactiveMedia.manageQuestions.href(
        marketListingKey
      ),
      title: 'Questions',
    },
    {
      current: asPath.startsWith(
        routes.engagement.campaigns.href(marketListingKey)
      ),
      href: routes.engagement.campaigns.href(marketListingKey),
      permission: Permissions.commsEmailsAdmin,
      title: 'Campaigns',
    },
    {
      current: asPath.startsWith(
        routes.engagement.webinars.href(marketListingKey)
      ),
      href: routes.engagement.webinars.href(marketListingKey),
      isBeta: isWebinarsEnabled,
      isComingSoon: !isWebinarsEnabled,
      permission: Permissions.webinarsAdmin,
      title: 'Webinars',
    },
    {
      current: asPath.startsWith(
        routes.engagement.utmLinks.href(marketListingKey)
      ),
      href: routes.engagement.utmLinks.href(marketListingKey),
      title: 'Links',
    },
  ];

  const investorMenuItems = () => [
    {
      current: asPath.startsWith(
        routes.investors.search.href(marketListingKey)
      ),
      href: routes.investors.search.href(marketListingKey),
      title: 'All investors',
    },
    {
      current: asPath.startsWith(
        routes.engagement.audiences.dynamicLists.href(marketListingKey)
      ),
      href: routes.engagement.audiences.dynamicLists.href(marketListingKey),
      title: 'Segments',
    },
    // TAGS TODO: Change this when new tags are released
    ...(isStaticListsFlagEnabled
      ? [
          {
            current: asPath.startsWith(
              routes.engagement.audiences.staticLists.href(marketListingKey)
            ),
            href: routes.engagement.audiences.staticLists.href(
              marketListingKey
            ),
            title: 'Tags',
          },
        ]
      : []),
  ];

  const intelligenceMenuItems = () => [
    {
      current: asPath.startsWith(
        routes.engagement.analytics.href(marketListingKey)
      ),
      href: routes.engagement.analytics.href(marketListingKey),
      title: 'Engagement',
    },
    ...(isPremium || (isComputershare && !isUK)
      ? [
          {
            current: asPath.startsWith(
              routes.investors.shareholderInsights.href(marketListingKey)
            ),
            href: routes.investors.shareholderInsights.holdings.href(
              marketListingKey
            ),
            permission: Permissions.registersShareholdingsAdmin,
            title: 'Investors',
          },
          {
            current:
              asPath ===
              routes.investors.registerOverview.href(marketListingKey),
            href: routes.investors.registerOverview.href(marketListingKey),
            permission: Permissions.registersShareholdingsAdmin,
            title: 'Registry',
          },
        ]
      : []),
    ...(isBeneficialOwnersReportsMenuEnabled && (isPremium || isUK)
      ? [
          {
            current: asPath.startsWith(
              routes.investors.beneficialOwners.href(marketListingKey)
            ),
            href: routes.investors.beneficialOwners.href(marketListingKey),
            isComingSoon: !isUK && isBeneficialOwnersReportsMenuEnabled,
            permission: Permissions.registersShareholdingsAdmin,
            title: translate('beneficialOwners.tab'),
          },
        ]
      : []),
    ...(isPremium && !isUK
      ? [
          {
            current: asPath.startsWith(
              routes.intelligence.boardReport.href(marketListingKey)
            ),
            href: routes.intelligence.boardReport.href(marketListingKey),
            permission: Permissions.companiesBoardReportsAdmin,
            title: 'Board report',
          },
        ]
      : []),
    ...(keyMetricsEnabled
      ? [
          {
            current: asPath.startsWith(
              routes.intelligence.keyMetrics.href(marketListingKey)
            ),
            href: routes.intelligence.keyMetrics.href(marketListingKey),
            title: 'Key metrics',
          },
        ]
      : []),
  ];

  const raiseCapitalMenuItems = () => [
    ...(isPremium
      ? [
          ...(sppEstimationEnabled
            ? [
                {
                  current:
                    asPath ===
                    routes.raiseCapital.sppEstimationV2.href(marketListingKey),
                  href: routes.raiseCapital.sppEstimationV2.href(
                    marketListingKey
                  ),
                  onClick: () =>
                    analytics.track('spp_estimation_v2_in_side_bar_clicked'),
                  permission: Permissions.registersShareholdingsAdmin,
                  title: 'Predictions',
                },
              ]
            : []),
        ]
      : []),
    ...(!isUK && hasCompanyShareholderOfferPermission && hasWebsitePermission
      ? [
          {
            Icon: CurrencyDollarIcon,
            current:
              asPath ===
              routes.raiseCapital.shareholderOffers.href(marketListingKey),
            href: routes.raiseCapital.shareholderOffers.href(marketListingKey),
            onClick: () =>
              analytics.track('shareholder_offers_in_side_bar_clicked'),
            permission: Permissions.registersShareholdingsAdmin,
            title: 'Raise builder',
          },
        ]
      : []),
    ...(isPremium
      ? [
          ...(pastPlacementsData?.pastPlacements &&
          pastPlacementsData.pastPlacements.length > 0
            ? [
                {
                  current:
                    asPath ===
                    routes.raiseCapital.pastPlacements.href(marketListingKey),
                  href: routes.raiseCapital.pastPlacements.href(
                    marketListingKey
                  ),
                  onClick: () =>
                    analytics.track('past_placements_in_side_bar_clicked'),
                  permission: Permissions.registersShareholdingsAdmin,
                  title: 'History',
                },
              ]
            : []),
        ]
      : []),
  ];

  const intelligenceMenuItemsWithPermissions = useMenuItemsWithPermissions(
    intelligenceMenuItems()
  );

  const raiseCapitalMenuItemsWithPermissions = useMenuItemsWithPermissions(
    raiseCapitalMenuItems()
  );

  return (
    <nav className="flex-1 space-y-2 px-2">
      <DashboardMenuItem
        Icon={RectangleGroupIcon}
        current={asPath === routes.home.href(marketListingKey)}
        href={routes.home.href(marketListingKey)}
        title="Dashboard"
      />
      <DashboardMenuDisclosure
        // Uncomment this after moving the files/folders around
        // defaultOpen={!!asPath.match(/\/engagement\//)}
        Icon={Square2StackIcon}
        items={useMenuItemsWithPermissions(engagementMenuItems())}
        title="Engage"
      />
      <DashboardMenuDisclosure
        Icon={UserGroupIcon}
        items={useMenuItemsWithPermissions(investorMenuItems())}
        title="Audience"
      />

      {/* Enabling  */}
      {!isUK && !isPremium ? (
        <DashboardMenuItem
          Icon={ChartBarIcon}
          current={
            asPath === routes.engagement.analytics.href(marketListingKey)
          }
          href={routes.engagement.analytics.href(marketListingKey)}
          title="Analyse"
        />
      ) : (
        <>
          {intelligenceMenuItemsWithPermissions.length > 0 && (
            <DashboardMenuDisclosure
              Icon={ChartBarIcon}
              items={intelligenceMenuItemsWithPermissions}
              title="Analyse"
            />
          )}
        </>
      )}

      {((isPremium &&
        !isUK &&
        raiseCapitalMenuItemsWithPermissions.length > 0) ||
        (!isPremium && hasCompanyShareholderOfferPermission)) && (
        <DashboardMenuDisclosure
          Icon={CurrencyDollarIcon}
          items={raiseCapitalMenuItemsWithPermissions}
          title="Raise"
        />
      )}
    </nav>
  );
};

export default DashboardMenu;
