import { useEffect } from 'react';
import { ArrowRightIcon } from '@heroicons/react/solid';
import dayjs from 'dayjs';
import Link from 'next/link';
import { useAutoSaveState } from '@leaf/helpers';
import { useRegistryImportStatusQuery } from '@/apollo/generated';
import Button from '@/components/ui/button';
import Typography from '@/components/ui/typography';
import { useCurrentCompanyProfileUser } from '@/contexts/current-company-profile-user-context';
import routes from '@/utils/routes';

const canShowBanner = (
  latestReportDate?: string,
  lastDissmissedAt?: string
) => {
  if (!latestReportDate || latestReportDate == '') {
    return true;
  }

  const isOutdated = dayjs(latestReportDate).isBefore(
    dayjs().subtract(1, 'month')
  );

  const isDismissed =
    lastDissmissedAt && lastDissmissedAt !== ''
      ? dayjs(lastDissmissedAt).isAfter(dayjs().subtract(1, 'day'))
      : false;

  return isOutdated && !isDismissed;
};

const ImportOutdatedBanner = () => {
  const { currentCompanyProfileUser } = useCurrentCompanyProfileUser();
  const { data } = useRegistryImportStatusQuery();
  const [latestReportDate, setLatestReportDate] = useAutoSaveState(
    '',
    'latestReportDate'
  );
  const [lastDissmissedAt, setLastDissmissedAt] = useAutoSaveState(
    '',
    'lastDissmissedAt'
  );

  const {
    ticker: { listingKey },
  } = currentCompanyProfileUser.profile;

  useEffect(() => {
    if (data?.registryImportStatus?.latestReportDate) {
      setLatestReportDate(data?.registryImportStatus?.latestReportDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!data?.registryImportStatus?.lastSuccessfulRegisterImportAt) {
    return null;
  }

  if (!canShowBanner(latestReportDate, lastDissmissedAt)) {
    return null;
  }

  return (
    <div className="z-banner fixed inset-x-4 bottom-6 md:pl-64">
      <div className="bg-amplify-green-700 relative m-auto flex w-fit flex-col gap-2 rounded-lg p-4 shadow-lg md:flex-row md:items-center md:gap-4">
        <div
          className="flex max-w-[calc(100%-1rem)] flex-col items-start text-white md:flex-row md:items-center"
          data-testid="registry-message"
        >
          <Typography>
            <span className="font-semibold">
              📊 Your registry data is current up to{' '}
              {dayjs(data?.registryImportStatus?.latestReportDate).format(
                'DD/MM/YYYY'
              )}{' '}
              ·
            </span>
            &nbsp; Information on this page may be outdated.
          </Typography>
        </div>

        <Link href={routes.settings.registry.href(listingKey)}>
          <Button
            TrailingIcon={() => <ArrowRightIcon className="h-4 w-4" />}
            className="w-full"
            size="sm"
            variant="secondary-neon"
            onClick={() => {
              setLastDissmissedAt(dayjs().format('YYYY-MM-DD'));
            }}
          >
            Upload registry data
          </Button>
        </Link>

        <div className="absolute right-4 top-3 md:static md:content-evenly md:pl-4">
          <button
            className="hover:bg-amplify-green-600 inline-flex h-7 w-7 shrink-0 items-center justify-center rounded-lg p-1.5 text-sm text-white hover:text-white"
            data-testid="dismiss-banner"
            type="button"
            onClick={() => {
              setLastDissmissedAt(dayjs().format('YYYY-MM-DD'));
            }}
          >
            <svg
              className="h-3 w-3 text-white"
              fill="none"
              viewBox="0 0 14 14"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImportOutdatedBanner;
